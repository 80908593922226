import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Text } from '~components/Typography';

const useStyles = makeStyles({
  card: {
    borderColor: 'rgba(75, 81, 97, 0.3)',
    height: '100%'
  },

  cardDonationAmount: {
    marginTop: '0.5em',
    marginBottom: '0.6em',
    color: '#438DCE',
    fontSize: 21.1,
    fontFamily: 'Inter',
    fontWeight: '700'
  },
  cardTitle: {
    marginTop: '0.5em',
    marginBottom: '0.7em',
    lineHeight: '130%',
    color: '#212121',
    fontSize: 19,
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  cardCursor: {
    cursor: props => (props.disabled ? 'default' : 'pointer'),
    height: '100%'
  },
  buttonCursor: {
    cursor: props => (props.disabled ? 'default' : 'pointer')
  },
  cardText: {
    lineHeight: '1.5',
    color: 'black',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '400'
  },
  cardImportantText: {
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: 'red'
  },
  featuresTitle: {
    marginTop: '2em',
    fontSize: '97%'
  },
  featuresLine: {
    marginTop: '0.5em',
    marginBottom: '0.5em'
  },
  noteBelow: {
    fontSize: '95%',
    fontStyle: 'italic',
    fontWeight: '400',
    color: 'rgba(75, 81, 97, 0.7)'
  }
});

const PerksFeatureCard = ({
  image,
  donationAmount,
  donationAmountSuffix,
  title,
  text,
  features,
  noteBelow,
  id,
  handleClick,
  disabled
}) => {
  const classes = useStyles({ disabled });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => !disabled && setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const handleMouseClick = () => !disabled && handleClick(id);

  return (
    <Box
      id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      position="relative"
      className={classes.cardCursor}
    >
      <Fade in={isHovered}>
        <Box
          bgcolor="#438dceD9"
          height="100%"
          width="100%"
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          zIndex="1000"
          color="#fff"
        >
          <Text>Select this Gift</Text>
        </Box>
      </Fade>

      <Card square variant="outlined" className={classes.card}>
        <CardActionArea className={classes.buttonCursor}>
          <CardMedia component="img" image={image.src} title={image.alt} />
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              color="primary"
              gutterBottom
              className={classes.cardDonationAmount}
            >
              {donationAmount}
              {donationAmountSuffix && (
                <span style={{ fontSize: '0.6em' }}>&nbsp;/&nbsp;{donationAmountSuffix}</span>
              )}
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className={classes.cardTitle}>
              {title}
            </Typography>

            {text &&
              text.map(paragraph => (
                <Text
                  key={paragraph}
                  color="textPrimary"
                  className={
                    paragraph.match(/^\[!\]/) ? classes.cardImportantText : classes.cardText
                  }
                >
                  {paragraph.replace(/^\[!\]/, '')}
                </Text>
              ))}

            {features && (
              <Text color="textPrimary" className={classes.featuresTitle}>
                Plus you&lsquo;ll get:
              </Text>
            )}

            <Box m={0} component="ul">
              {features &&
                features.map(feature => (
                  <li key={feature}>
                    <Text color="textPrimary" className={classes.featuresLine}>
                      {feature}
                    </Text>
                  </li>
                ))}
            </Box>

            {noteBelow && (
              <Text color="textSecondary" className={classes.noteBelow}>
                {noteBelow}
              </Text>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

PerksFeatureCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  donationAmount: PropTypes.string.isRequired,
  donationAmountSuffix: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  noteBelow: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

PerksFeatureCard.defaultProps = {
  features: null,
  donationAmountSuffix: undefined,
  noteBelow: undefined,
  disabled: false
};
export default PerksFeatureCard;
