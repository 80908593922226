import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import MediaPlayer from '~components/media-player';
import { Text } from '~components/Typography';
import Social from '~components/social/Social';

import PerksHeroBackgroundImg from './images/perks-hero-background.svg';
import FacebookIcon from './images/facebook.svg';
import TwitterIcon from './images/twitter.svg';
import EmailIcon from './images/email.svg';
import renderer from './countdownRenderer';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
    fontSize: 52,
    fontFamily: 'Inter',
    fontWeight: '600',
    textAlign: 'center',
    maxWidth: '740px',
    margin: 'auto',
    marginBottom: '45px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      fontWeight: '700',
      marginBottom: '38px',
      marginTop: '-24px'
    }
  },
  containerPosition: {
    marginTop: '-48px',
    marginBottom: '0px'
  },
  videoPlayerWidth: {
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '80%'
    }
  }
}));

const PerksHero = ({ title, videoWistiaId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section>
      <Container
        size={66}
        center
        background={`url(${PerksHeroBackgroundImg})`}
        className={classes.containerPosition}
      >
        <Box pt={isSmallerThanMd ? 12 : 10} width="100%">
          <Box py={2}>
            <Countdown date="2024-07-16T00:00:00.000-04:00" renderer={renderer} />
          </Box>
          <h1 className={classes.title}>{title}</h1>
        </Box>

        <Box
          p={isSmallerThanMd ? 0 : 1}
          pb={isSmallerThanMd ? 3 : 1}
          className={classes.videoPlayerWidth}
        >
          <MediaPlayer url={videoWistiaId} playing />
        </Box>

        <Box
          flexDirection="row"
          display="flex"
          alignItems="center"
          color="#fff"
          pb={1}
          pt={isSmallerThanMd ? 1 : 0}
        >
          <Text className="non-existing-class-to-overwrite-internal-styling" color="textPrimary">
            Spread the word:
          </Text>
          <Social
            facebookIcon={<img src={FacebookIcon} alt="" style={{ marginLeft: '1em' }} />}
            twitterIcon={<img src={TwitterIcon} alt="" style={{ marginLeft: '1em' }} />}
            emailIcon={<img src={EmailIcon} alt="" style={{ marginLeft: '1em' }} />}
          />
        </Box>
      </Container>
    </section>
  );
};

PerksHero.propTypes = {
  title: PropTypes.string.isRequired,
  videoWistiaId: PropTypes.string.isRequired
};

PerksHero.defaultProps = {};
export default PerksHero;
