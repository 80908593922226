import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Element } from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useCheckout from '~hooks/useCheckout';
import products from '~utils/products';
import DonationAmountForm from '~components/donate/DonateForm/DonateAmountForm';

import Container from '~components/Container';
import FEATURE_CARDS_CONTENT from './PerksFeatures.content';
import PerksFeatureCard from './PerksFeatureCard';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '58px',
    backgroundColor: '#F7F6F4'
  },
  title: {
    color: 'black',
    fontSize: 53,
    fontFamily: 'Inter',
    fontWeight: '600',
    textAlign: 'center',
    maxWidth: '950px',
    margin: 'auto',
    marginBottom: '64px',
    [theme.breakpoints.down('xs')]: {
      color: '#222222',
      fontSize: 32,
      fontWeight: '700',
      marginTop: '40px',
      marginBottom: '40px'
    }
  }
}));

const PerksFeatures = () => {
  const classes = useStyles();
  const [, setCheckoutState] = useCheckout();
  const [donationAmount, setDonationAmount] = useState();
  const [includeFees, setIncludeFees] = useState(false);

  const handleFeatureCardClick = donationId => {
    const product = products.find(({ id }) => id === donationId);
    setCheckoutState({ product });
    navigate('/checkout');
  };

  const changeDonationFeesInclusion = (e, checked) => {
    setIncludeFees(checked);
  };

  return (
    <section className={classes.root}>
      <Container>
        <h3 className={classes.title}>Choose from the list of custom, curated gifts below!</h3>
        <Grid container spacing={3}>
          {FEATURE_CARDS_CONTENT.map(feature => (
            <Grid item xs={12} sm={6} md={4} key={feature.title}>
              <Box
                component={Element}
                name={feature.donationAmount}
                key={feature.donationAmount}
                py={1}
                height="100%"
              >
                <PerksFeatureCard {...feature} handleClick={handleFeatureCardClick} />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Box p={2} mt={1} border={1} borderColor="rgba(75, 81, 97, 0.3)" bgcolor="#FFFFFF">
              <Typography variant="h6" gutterBottom>
                Make a Pledge without a Gift
              </Typography>
              <DonationAmountForm
                donationAmount={donationAmount}
                handleDonationAmount={setDonationAmount}
                changeDonationFeesInclusion={changeDonationFeesInclusion}
                includeFees={includeFees}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PerksFeatures;
