import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  timerStyle: {
    width: '100%',
    fontSize: '64px',
    fontWeight: '600',
    lineHeight: '64px',
    textAlign: 'center',
    display: 'inline-block',
    color: '#0D0D79',
    minWidth: '80px',
    borderRadius: '2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
      lineHeight: '50px',
      minWidth: '65px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      lineHeight: '32px',
      minWidth: '55px'
    }
  },
  timerInfo: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '12px',
    letterSpacing: '0.1em',
    display: 'block',
    marginTop: '7px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#0D0D79',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '10px'
    }
  },
  overlineText: {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '500',
    color: '#FFDBA3',
    opacity: '0.87',
    marginBottom: '13px',
    textAlign: 'center',
    display: 'block',
    marginTop: '20px'
  },
  timerBox: {
    background: '#DFEFFF',
    borderRadius: 6,
    padding: '14px 7px 24px !important',
    minWidth: '107px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '70px',
      marginTop: '-20px'
    }
  }
}));

const CountdownDigits = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.timerStyle}>{children}</span>;
};
CountdownDigits.propTypes = {
  children: PropTypes.node.isRequired
};

const CountdownTimers = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.timerInfo}>{children}</span>;
};
CountdownTimers.propTypes = {
  children: PropTypes.node.isRequired
};

const CountDownItem = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item xs="auto" className={classes.timerBox}>
      {children}
    </Grid>
  );
};

CountDownItem.propTypes = {
  children: PropTypes.node.isRequired
};

const renderer = ({ days, hours, minutes, seconds }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="flex-start"
    spacing={3}
    style={{ marginTop: '21px', marginBottom: '24px', gap: '11px' }}
  >
    <CountDownItem>
      <CountdownDigits>{days}</CountdownDigits>
      <br />
      <CountdownTimers>Days</CountdownTimers>
    </CountDownItem>

    <CountDownItem>
      <CountdownDigits>{hours}</CountdownDigits>
      <br />

      <CountdownTimers>hours</CountdownTimers>
    </CountDownItem>

    <CountDownItem>
      <CountdownDigits>{minutes}</CountdownDigits>
      <br />

      <CountdownTimers>minutes</CountdownTimers>
    </CountDownItem>

    <CountDownItem>
      <CountdownDigits>{seconds}</CountdownDigits>
      <br />

      <CountdownTimers>seconds</CountdownTimers>
    </CountDownItem>
  </Grid>
);

renderer.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired
};

export default renderer;
