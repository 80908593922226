import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';

import { PerksHero, PerksCampaign, PerksFeatures, PerksGratitude } from '~modules/perks';
import LoadingSpinner from '~components/LoadingSpinner';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

const isPerksPageEnabled = isFeatureEnabled(FEATURE_FLAGS.PERKS_PAGE);

const seo = {
  seo_title: 'Support Aleph Beta and Receive a Gift!',
  seo_description:
    'Aleph Beta’s end-of-year giving campaign is a chance for you to partner with us in creating innovative Torah, and a chance for us to thank you for your generosity. Enter into our raffle for your chance to win exciting prizes including a trip for two to Yellowstone and Grand Teton National Parks with Rabbi Fohrman.',
  seo_keywords: 'jewish gifts, jewish fundraising, jewish raffles, parsha perks',
  pageCanonicalUrl: 'https://www.alephbeta.org/perks'
};

const HERO_DATA = {
  title: 'Support Aleph Beta & Receive a Gift!',
  videoWistiaId: 'https://clevertech.wistia.com/medias/vfauu83mmy'
};

const Perks = () => {
  useEffect(() => {
    if (!isPerksPageEnabled) {
      navigate('/');
    }
  }, []);

  if (!isPerksPageEnabled) {
    return <LoadingSpinner />;
  }

  return (
    <Page hideExitIntent redirectToMembers>
      <SEO {...seo} />
      <PerksHero {...HERO_DATA} />
      <PerksCampaign />
      <PerksFeatures />
      <PerksGratitude />
    </Page>
  );
};
Perks.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};
export default Perks;
