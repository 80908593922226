import Perk1 from './images/perk_01.jpg';
import Perk2 from './images/perk_02.jpg';
import Perk3 from './images/perk_03.jpg';
import Perk4 from './images/perk_04.jpg';
import Perk5 from './images/perk_05.jpg';
import Perk6 from './images/perk_06.jpg';
import Perk7 from './images/perk_07.jpg';
import Perk8 from './images/perk_08.jpg';
import Perk9 from './images/perk_09.jpg';
import Perk10 from './images/perk_10.jpg';
import Perk11 from './images/perk_11.jpg';
import Perk12 from './images/perk_12.jpg';
import Perk13 from './images/perk_13.jpg';
import Perk14 from './images/perk_14_sold_out.png';
import Perk15 from './images/perk_15.jpg';
import Perk16 from './images/perk_16.jpg';

export default [
  {
    image: {
      src: Perk1,
      alt: 'Aleph Beta Magnet'
    },
    id: 71,
    donationAmount: 'Donate $36',
    title: 'Aleph Beta Magnet',
    text: [
      `Show your support for Aleph Beta with this nifty magnet! Slap it on your car, or display it prominently on your fridge. How fun would it be to drive by another Aleph Beta member on the road?`
    ]
  },
  {
    image: {
      src: Perk2,
      alt: 'The NEW 2024 Aleph Beta T-Shirt'
    },
    id: 72,
    donationAmount: 'Donate $50',
    title: 'The NEW 2024 Aleph Beta T-Shirt',
    text: [
      `Keep Aleph Beta close to your heart while enjoying this luxurious t-shirt — a must-have for your summer wardrobe!`
    ]
  },
  {
    image: {
      src: Perk3,
      alt: 'Raffle Ticket'
    },
    id: 73,
    donationAmount: 'Donate $100',
    title: 'Raffle Ticket',
    text: [
      `Win two round trip tickets to Israel for the Yimei Iyun b’Tanach (Bible Study Conference). Plus, discuss the depths of Torah while enjoying dinner with Rabbi Fohrman. Pack your bags, and don’t forget all your big questions at home!`
    ]
  },
  {
    image: {
      src: Perk4,
      alt: '3 Raffle Tickets'
    },
    id: 75,
    donationAmount: 'Donate $180',
    title: '3 Raffle Tickets',
    text: [
      `Increase your odds! As a thank you for your donation, you will receive 3 entries into the grand prize raffle to win two tickets to Israel.`
    ]
  },
  {
    image: {
      src: Perk5,
      alt: 'Short Story by Rabbi Fohrman and ChatGPT + 1 Raffle Ticket'
    },
    id: 74,
    donationAmount: 'Donate $180',
    title: 'Short Story by Rabbi Fohrman and ChatGPT + 1 Raffle Ticket',
    text: [
      `Get a signed copy of a short story written by Rabbi Fohrman, playfully in collaboration with ChatGPT, about an encounter between Emily Dickenson and the Kotzker Rebbe. We’ll also throw in a raffle ticket.`
    ]
  },
  {
    image: {
      src: Perk6,
      alt: 'Framed Original Canvas + 1 Raffle Ticket'
    },
    id: 76,
    donationAmount: 'Donate $250 ',
    title: 'Framed Original Canvas + 1 Raffle Ticket',
    text: [
      `This artwork is sure to spark conversation among guests and family members, providing an engaging way to discuss and explore the foundational stories of the book of Genesis.`
    ]
  },
  {
    image: {
      src: Perk7,
      alt: 'The Aleph Beta Merch Bundle'
    },
    id: 77,
    donationAmount: 'Donate $360',
    title: 'The Aleph Beta Merch Bundle',
    text: [
      `Mugs, T-Shirts, Magnets - why choose? Just get them all! This is the perfect gift for an Aleph Beta fan. Heck, it’s the perfect gift for you! Treat yourself, and express your true Aleph Beta fanhood!`
    ]
  },
  {
    image: {
      src: Perk8,
      alt: '6 Original Edition Aleph Beta Mugs'
    },
    id: 78,
    donationAmount: 'Donate $360',
    title: '6 Original Edition Aleph Beta Mugs',
    text: [
      `A Rabbi Fohrman household favorite - buy a set of six mugs for your family! These mugs are the original edition, featuring our cute cartoon characters from way back in the day. Your whole family can sip coffee or hot chocolate together, bonding over your love of Torah and Aleph Beta.`
    ]
  },
  {
    image: {
      src: Perk9,
      alt: 'Signed Copy of Pre-Publication Manuscript of Numbers Parsha Companion + 5 Raffle Tickets'
    },
    id: 79,
    donationAmount: 'Donate $500',
    title:
      'Signed Copy of Pre-Publication Manuscript of Numbers Parsha Companion + 5 Raffle Tickets',
    text: [
      `Be one of the first to read Rabbi Fohrman’s latest manuscript that won’t be available for print for at least another year! Since we’re currently reading Sefer Bamidbar, we'll also send you the digital PDF for you to enjoy straight away.`
    ]
  },
  {
    image: {
      src: Perk10,
      alt: 'Coffee Table Book Parsha Guides + 5 Raffle Tickets'
    },
    id: 80,
    donationAmount: 'Donate $500',
    title: 'Coffee Table Book Parsha Guides + 5 Raffle Tickets',
    text: [
      `Before Rabbi Fohrman’s Parsha Companion Series, we created a “Dvar Torah Guide” on every parsha. This Coffee Table book contains a parsha guide for every parsha in the Torah. Beautifully printed, full-color, it is sure to dazzle your friends, help you learn with your kids and enable you to deliver a great dvar Torah every week!`
    ]
  },
  {
    image: {
      src: Perk11,
      alt: 'Ticket to Special Live Event: “Hollywood and the Rabbis” + 10 Raffle Tickets'
    },
    id: 81,
    donationAmount: 'Donate $1,000',
    title: 'Ticket to Special Live Event: “Hollywood and the Rabbis” + 10 Raffle Tickets',
    text: [
      `Join Rabbi Fohrman and Imu, at a private movie theater in NYC for a screening of scenes from Rabbi Fohrman’s favorite films with his commentary. This event is not-to-be-missed! Rabbi Fohrman seems to find powerful Torah messages everywhere! Exact date TBD.`
    ]
  },
  {
    image: {
      src: Perk12,
      alt: 'Listed as a supporter in acknowledgements of Rabbi Fohrman’s next book (Bamidbar) + 15 Raffle Tickets'
    },
    id: 82,
    donationAmount: 'Donate $1,800',
    title:
      'Listed as a supporter in acknowledgements of Rabbi Fohrman’s next book (Bamidbar) + 15 Raffle Tickets',
    text: [
      `Your support makes a really meaningful difference in our work. To recognize your friendship and your investment in us, we will proudly list your name in the acknowledgement section of Rabbi Fohrman’s next Parsha Companion book.`
    ]
  },
  {
    image: {
      src: Perk13,
      alt: 'Signed Early Manuscript of: The Beast That Crouches at the Door +15 Raffle Tickets (ONLY 3 available)'
    },
    id: 83,
    donationAmount: 'Donate $3,600',
    title:
      'Signed Early Manuscript of: The Beast That Crouches at the Door +15 Raffle Tickets (ONLY 3 available)',
    text: [
      `Get your hands on the last 3 original, signed manuscripts of Rabbi Fohrman’s critically acclaimed book, The Beast That Crouches at the Door. This is a standout collector’s item for any Rabbi Fohrman book superfans!`
    ]
  },
  {
    image: {
      src: Perk14,
      alt: 'Framed and Mounted Actual Wacom Tablet that Rabbi Fohrman Used to Create the First Videos on Aleph Beta, with Plaque of Authenticity + 15 Raffle Tickets (ONLY 1 on offer)'
    },
    id: 84,
    donationAmount: 'Donate $5,000',
    title:
      'Framed and Mounted Actual Wacom Tablet Rabbi Fohrman Used to Create the First Aleph Beta  Videos, with Plaque of Authenticity + 15 Raffle Tickets (ONLY 1)',
    text: [
      `Own a piece of history! Aleph Beta was born when Rabbi Fohrman learned to illustrate chiasms on the screen, when he highlighted his first intertextual parallels. This tablet holds a very fond place in Rabbi Fohrman’s heart because it represents the very birth of Aleph Beta.`
    ],
    disabled: true
  },
  {
    image: {
      src: Perk15,
      alt: 'Bar and Bat Mitzvah Learning with Rabbi Fohrman + 15 Raffle Tickets'
    },
    id: 85,
    donationAmount: 'Donate $5,000',
    title: 'Bar and Bat Mitzvah Learning with Rabbi Fohrman + 15 Raffle Tickets',
    text: [
      `Worried about writing that dreaded speech for an upcoming bar/bat mitzvah? Rabbi Fohrman will learn with your child, delving deeply into their bar/bat Mitzvah Parsha and help prepare a speech. Or, better yet, we can record their learning session and turn it into a beautifully edited podcast episode that you can send to all of your guests.`
    ]
  },
  {
    image: {
      src: Perk16,
      alt: 'Fly to Israel and Tour the Old City with Rabbi Fohrman and his Favorite Tour Guide'
    },
    id: 86,
    donationAmount: 'Donate $15,000',
    title: 'Fly to Israel and Tour the Old City with Rabbi Fohrman and his Favorite Tour Guide',
    text: [
      `Don’t leave the result of the raffle up to chance!  We’ll take care of your flights to Israel and arrange a private tour of the Old City with Rabbi Fohrman’s favorite tour guide: Barnea Selevan. A better guide you cannot find. And, for good measure, Rabbi Fohrman will tag along! Fly to Israel (at a timing of your choice), experience the mysteries of the old city, hang out with Rabbi Fohrman, and support your favorite organization, all in one fell swoop!`
    ]
  }
];
