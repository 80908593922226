import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
    color: 'black',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  accordionExpandIcon: {
    color: '#000000'
  }
}));

const FAQ_ITEMS = [
  {
    key: 'faq-1',
    title: 'When will the Raffle take place?',
    description: `Rabbi Fohrman will select the winner on July 16th, 2024 at 12:00 PM EST.`
  },
  {
    key: 'faq-2',
    title: 'About Yemei Iyun b’Tanach',
    description: `For over 25 years, Herzog College, an academic institution affiliated with Yeshivat Har Etzion in Alon Shvut, has been organizing week-long Bible Study Days - “Yemei Iyun b’Tanach” - that include dozens of lectures every day presented by academics and Bible experts like Rabbi Fohrman.`
  },
  {
    key: 'faq-3',
    title: 'When is the trip?',
    description: `This year, the Yimei Iyun b’Tanach begin on August 6, 2024. But you may choose to postpone your trip until next year’s conference, which is scheduled for July 2025 (exact dates TBD).`
  },
  {
    key: 'faq-4',
    title: 'What does Aleph Beta cover in price?',
    description: `Aleph Beta will cover flights, the cost of attending the Yimei Iyun, and dinner with Rabbi Fohrman. Accommodations and travel (transportation to and from airport and around Israel) are not included.`
  }
];

const Accordion = withStyles(theme => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    '& > .MuiAccordionSummary-expandIcon': {
      padding: 0
    },
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#F7F6F4'
    }
  },
  expanded: {}
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: '24px',
    '&$expanded': {
      minHeight: '24px'
    }
  },
  content: {
    margin: '8px 0',
    '&$expanded': {
      margin: '8px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    color: '#0D1216',
    fontSize: 14,
    fontFamily: 'Inter'
  }
})(MuiAccordionDetails);

const PerksCampaignFaq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {FAQ_ITEMS.map((item, index) => (
        <Accordion
          key={item.key}
          className={classes.accordion}
          expanded={expanded === item.key}
          onChange={handleChange(item.key)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.accordionExpandIcon} />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <h6 className={classes.title}>{item.title}</h6>
          </AccordionSummary>
          <AccordionDetails>{item.description}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default PerksCampaignFaq;
