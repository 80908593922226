import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '~components/Link';
import Divider from '~components/Divider';
import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '100px',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10px',
      backgroundColor: '#F7F6F4'
    }
  }
}));

const PerksGratitude = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="h4" gutterBottom>
          Thank you for your support!
        </Typography>
        <Box py={2}>
          <Typography variant="subtitle1" gutterBottom>
            Prefer to purchase a perk with a check? No problem. Find our billing address below.
            Simply email info@alephbeta.org and tell us which perk you fancy.
          </Typography>
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          Alternatively, checks may be mailed to:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Hoffberger Institute for Text Study, Inc.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          3 E Evergreen Rd, Unit 101 #708
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          New City, NY 10956
        </Typography>
        <Box py={2}>
          <Typography variant="h6" gutterBottom>
            Have Questions?
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Call or email our Support Team at:
          </Typography>
        </Box>
        <Box pb={2} pt={1}>
          <Link to="mailto:info@alephbeta.org?Subject=Need Help?">
            <Typography variant="subtitle1">info@alephbeta.org</Typography>
          </Link>
          <Link to="tel:(516)253-5691" variant="subtitle1" gutterBottom>
            (516) 253-5691
          </Link>
        </Box>
        <Box py={2}>
          <Typography variant="subtitle1" gutterBottom>
            All physical products are eligible for free shipping within the continental United
            States. Some gifts require customization or special order and will thus not be shipped
            immediately.
          </Typography>
        </Box>
        <Divider />
        <Box py={2}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            *To enter the raffle, you must purchase one or more raffle tickets between June 24 and
            July 15 2024. The winner will be notified on July 16, 2024
          </Typography>
        </Box>
      </Container>
    </section>
  );
};

export default PerksGratitude;
