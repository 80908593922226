import React from 'react';
import { Element } from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '~components/Container';
import PerksCampaignImage from './images/grand-prize.jpg';
import PerksCampaignMobileImage from './images/grand-prize-mobile.jpg';
import ListTickImg from './images/list-tick.svg';
import PerksCampaignFaq from './PerksCampaignFaq';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F7F6F4',
    paddingTop: '45px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px'
    }
  },
  aboutCampaign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    textAlign: 'center',
    '& > h3': {
      marginTop: 0,
      marginBottom: 0,
      color: 'black',
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: '700',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24
      }
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      alignItems: 'center'
    },
    '& > div > p': {
      marginTop: 0,
      marginBottom: 0,
      color: 'black',
      fontSize: 20,
      fontFamily: 'Inter',
      fontWeight: '400',
      maxWidth: '900px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16
      }
    },
    '& > div > p:last-child': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '276px'
      }
    }
  },
  grandPrizeBox: {
    marginTop: '43px',
    marginBottom: '100px',
    background: 'white',
    borderRadius: 20,
    border: '1px rgba(0, 0, 0, 0.20) solid',
    padding: '37px 38px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      border: 'none',
      marginBottom: '40px',
      background: '#F7F6F4'
    }
  },
  grandPrizeImage: {
    borderRadius: 10,
    width: '100%'
  },
  grandPrizeDescriptionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    margin: '37px',
    '& > *': {
      flex: 1
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '16px 0px'
    }
  },
  infoTextBox: {
    maxWidth: '443px',
    '& > h5': {
      marginTop: 0,
      marginBottom: 8,
      color: 'black',
      fontFamily: 'Inter',
      fontSize: 28,
      fontWeight: '700',
      lineHeight: '140%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24
      }
    },
    '& > p': {
      marginTop: 0,
      marginBottom: 0,
      color: '#0D1216',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: '400'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '350px'
    }
  },
  featureListTitleText: {
    marginTop: 24,
    marginBottom: 8,
    color: '#0D1216',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '700'
  },
  includeList: {
    marginTop: 0,
    marginBottom: 0,
    listStyleImage: `url(${ListTickImg})`,
    color: '#0D1216',
    fontSize: 14,
    fontFamily: 'Inter',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '350px'
    }
  }
}));

const PerksCampaign = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <section className={classes.root}>
      <Container center>
        <Element name="How It Works">
          <div className={classes.aboutCampaign}>
            <h3>About this campaign</h3>
            <div>
              <p>
                <b>Aleph Beta’s Perks campaign</b> is a chance for you to partner with us, and a
                chance for us to thank you for your generosity. Every dollar that you contribute is
                helping to foster profound, Torah-rooted transformations for countless learners
                across the globe.
              </p>
              <p>Choose a donation amount, and we’ll send the gift described!</p>
            </div>
          </div>

          <div className={classes.grandPrizeBox}>
            <img
              src={isMobileDevice ? PerksCampaignMobileImage : PerksCampaignImage}
              alt="alt"
              className={classes.grandPrizeImage}
            />
            <div className={classes.grandPrizeDescriptionBox}>
              <div>
                <div className={classes.infoTextBox}>
                  <h5>Fly to Israel and Learn Torah with Rabbi Fohrman</h5>
                  <p>
                    Join Rabbi Fohrman in Israel for the Yimei Iyun b’Tanach (Bible Study
                    Conference) in Alon Shvut.
                  </p>
                </div>

                <div>
                  <h6 className={classes.featureListTitleText}>This trip Includes:</h6>
                  <ul className={classes.includeList}>
                    <li>
                      <b>Round trip tickets to Israel</b> for two from anywhere in the continental
                      United States
                    </li>
                    <li>
                      Attendance to the <b>Yimei Iyun at Herzog College</b>
                    </li>
                    <li>
                      Dinner for 2 <b>with Rabbi Fohrman</b>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {isMobileDevice && <hr />}
                <PerksCampaignFaq />
              </div>
            </div>
            <div />
          </div>
        </Element>
      </Container>
    </section>
  );
};

export default PerksCampaign;
